p {
  margin: 0px;
}

.row-p {
  margin-bottom: 0px;
  padding: 4px 8px;
}
.monitor-advanced-container {
  align-items: center;
  flex-direction: column;
  display: flex;
  margin-top: -5px;
}
.advanced-container {
  align-items: center;
  flex-direction: column;
  display: flex;
  margin-top: -5px;

  width: 22vw;
}
.chip-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.search-container {
  padding-right: 1vw;
  flex-direction: row;
  display: flex;
  width: 100vw;
  margin-top: -20px;
}

.sresults-container {
  display: flex;
  border-radius: 10px;
  padding-top: 20px;
  padding-bottom: 30px;
  min-height: 85vh;
  flex-direction: column;
}

/* Positions */
.position-outer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.position-title {
  font-family: "Raleway";
  font-weight: 300;
  margin: 10px 0px;
  font-size: 18px;
  text-align: center;
}
.position-row {
  display: flex;
  flex-direction: row;
  margin: 10px;
}
.position-tile {
  background-color: white;
  border: 2.5px solid white;
  border-radius: 15px;
  padding: 6px;
  width: 50px;
  margin: 0px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.position-tile-unselected {
  /* background-color: #003366; */
  border: 2.5px solid white;
  border-radius: 15px;
  padding: 6px;
  width: 50px;
  margin: 0px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.position-text {
  font-family: "Raleway";
  font-size: 16px;
}

/* Ranges */
.range-component {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  margin-bottom: 15px;
  min-width: 320px;
}
.range-title {
  font-size: 25px;
  font-family: "Raleway";
}
.range-row {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  margin-bottom: 20px;
  /* margin-top: -50px; */
}
.blackline {
  width: 150px;
}
.data-box {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
  margin-right: 15px;
  margin-left: 15px;
  width: 80px;
}
.data-text {
  font-family: "Raleway";
  font-weight: 300;
  font-size: 16px;
  white-space: nowrap;
  color: #fff;
}
.tile-outer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 5px 0px;
}
.range-tile {
  border: 2.5px solid white;
  border-radius: 15px;
  padding: 20px;
  width: 150px;
  margin: 0px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.summer-container {
  margin: 20px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.summer-search {
  border: 0px;
  border-radius: 9px;
  height: 40px;
  width: 300px;
  padding: 0px 10px;
  margin: 10px 0px;
}

.dropdown-container {
  margin: 40px 0px;
}

.search-button {
  border-color: #003366;
  background-color: #fff;
  padding: 0px 50px;
  height: 50px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.buttonish {
  cursor: pointer;
}

.mcc-token {
  background-color: #467db3;
  color: #fff;
}
