.outer-container {
  display: flex;
  flex-direction: row;
  height: 100vh;
  padding: 100px;
  background-color: #003366;
}

.overlay {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 40vw;
  height: 100vh;
  background: linear-gradient(
    to right,
    rgba(0, 51, 102, 0),
    rgba(0, 51, 102, 1)
  );
}

.signup-container {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 2rem 4rem;
  border-radius: 5px;
  background-color: #fff;
  color: #003366;
  min-width: 370px;
}

/* Scout Text */
.scoutnet-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
}

.signup-text {
  color: #003366;
  font-size: 25px;
}

/*  Inputs */

.affiliated-text {
  color: #003366;
  /* margin-top: 25px; */
  font-size: 14px;
}
.dropdown-container {
  background-color: slategray;
  /* margin-top: -15px; */
}

/* Accept Terms */
.accept-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  /* margin-top: -10px; */
}
.accept-left-text {
  font-size: 14px;
  color: #003366;
  margin-right: 4px;
}

/* Sign In Button */
.signin-button {
  background-color: #003366;
  padding: 0px 100px;
  height: 50px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.button-text {
  font-size: 28px;
}

/* Havent got an Account */
.havent-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 15px;
}

.havent-right-text-link {
  margin-left: 5px;
}
