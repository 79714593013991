.home-container {
  padding-left: 1rem;
  margin: 0px;
  display: flex;
}

.results-container {
  background-color: #292929;
  min-height: 85vh;
  flex: 1;
  display: flex;
  border-radius: 12px;
  padding-left: 10px;
  padding-right: 10px;
}

.empty-results-container {
  background-color: #292929;
  min-height: 85vh;
  flex: 1;
  display: flex;
  border-radius: 12px;
  padding-left: 10px;
  padding-right: 10px;
  align-items: center;
  flex-direction: column;
}
