.dialog {
    max-width: none !important;
    width: 95%;
}

.content {
    display: flex;
    height: "100%";
    height: 90vh;
    max-height: 90vh;
    /* justify-content: center; */
    /* align-items: center; */
}

.play-card:hover {
    border-color: #467DB3 !important;
}
