body {
  /* color: rgb(0, 51, 102); */
  background-color: #e6e6e6;
  font-family: "Raleway";
}

.rbt-input {
  min-width: 250px;
}

.dropdown-toggle::after {
  margin-left: 1.1em;
}

.bg-white-600 {
  background-color: #fff;
  color: #003366;
  display: flex;
  justify-content: space-between;
  border: 4px solid #003366;
  border-radius: 8px;
}

.bg-blue-600 {
  background-color: #003366;
  color: #fff;
  display: flex;
  justify-content: space-between;
  border: 4px solid #fff;
  border-radius: 8px;
}

.bg-dark-600 {
  background-color: #292929;
  color: #fff;
  display: flex;
  justify-content: space-between;
  border: 4px solid #fff;
  border-radius: 8px;
}

a.dropdown-item.active {
  background-color: #467db3;
}

.atc-dropdown-title {
  color: #fff;
  padding: 8px;
  margin-top: 8px;
}

.atc-dropdown-title:hover {
  color: #fff;
  text-decoration: none;
}

.atc-dropdown {
  margin-top: -6px;
  background-color: #467db3;
  color: #fff;
}

.atc-dropdown-red {
  margin-top: -6px;
  background-color: #a61911;
  color: #fff;
}

.atc-item {
  background: #003366;
  border: 1px solid #fff;
  padding: 8px 16px;
  border-radius: 3px;
  color: #fff;
}

.atc-item-red {
  background: #a61911;
  border: 1px solid #fff;
  padding: 8px 16px;
  border-radius: 3px;
  color: #fff;
}
.atc-item-red:hover {
  color: #fff;
  text-decoration: none;
}

.atc-item:hover {
  color: #fff;
  text-decoration: none;
}

.white-urls a {
  color: #fff;
  text-decoration: solid #fff underline;
}
