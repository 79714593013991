.outer-container-login {
  display: flex;
  flex-direction: row;
  background-color: #003366;
  padding: 100px;
  height: 100vh;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #fff;
  opacity: 0.5; /* Firefox */
}

.overlay {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 40vw;
  height: 100vh;
  background: linear-gradient(
    to right,
    rgba(0, 51, 102, 0),
    rgba(0, 51, 102, 1)
  );
}

.login-container {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 2rem 4rem;
  border-radius: 5px;
  background-color: #fff;
  color: #003366;
  min-width: 370px;
}

/* Scout Text */
.scoutnet-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.signin-text {
  color: #fff;
  font-size: 25px;
}

/* Email/Pass Input */
.textinput-container {
  flex-direction: column;
  display: flex;
  margin-bottom: 30px;
  /* margin-top: 30px; */
  gap: 30px;
}
form {
  display: flex;
  flex-direction: column;
}
.input {
  border: 0px solid;
  color: #003366;
  background-color: transparent;
  border-bottom: 2px solid #003366;
  /* width: 280px; */
}
::placeholder {
  color: #003366;
  opacity: 0.5;
}

/* Forgot Password */
.forgot-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 1rem;
}
.forgot-left-text {
  font-size: 14px;
  color: #003366;
}
.forgot-right-text {
  font-size: 14px;
  color: #003366;
  font-weight: 700;
}
.forgot-right-text-link {
  margin-left: 5px;
}

/* Sign In Button */
.signin-button {
  background-color: #003366;
  padding: 0px 100px;
  height: 50px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.button-text {
  font-size: 28px;
}

/* Havent got an Account */
.havent-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 15px;
}
.havent-left-text {
  font-size: 14px;
  color: #003366;
}
.havent-right-text {
  font-size: 14px;
  color: #003366;
  font-weight: 700;
}
.havent-right-text-link {
  margin-left: 5px;
}

.accept-blue-text {
  font-size: 14px;
  color: #003366;
  font-weight: 700;
  margin-right: 4px;
}
