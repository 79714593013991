$diamond-top: 150px;

@media screen and (min-width: 1300px) {
  $diamond-left: 350px;

  .players-overlay {
    left: 35px;
  }
  #field {
    position: absolute;
    top: $diamond-top - 110px;
    left: $diamond-left + 145px;
    height: 700px;
    width: 700px;
    border-radius: 1% 100% 1% 1%;
    border: 1px solid green;
    background-color: green;
    -webkit-transform: rotate(-45deg);
  }
  #in-field {
    position: absolute;
    top: $diamond-top + 170px;
    left: $diamond-left + 265px;
    height: 450px;
    width: 450px;
    border-radius: 1% 50% 1% 1%;
    border: 1px solid #836539;
    background-color: #836539;
    -webkit-transform: rotate(-45deg);
  }
  #base-lines {
    position: absolute;
    top: $diamond-top - 120px;
    left: $diamond-left + 155px;
    height: 670px;
    width: 670px;
    border-left: 2px solid white;
    border-bottom: 2px solid white;
    -webkit-transform: rotate(-45deg);
  }
  #in-field-grass {
    position: absolute;
    top: $diamond-top + 330px;
    left: $diamond-left + 359px;
    height: 260px;
    width: 260px;
    border: 2px solid green;
    background-color: green;
    -webkit-transform: rotate(-45deg);
  }
  #first-base {
    top: $diamond-top + 450px;
    left: $diamond-left + 706px;
  }
  #second-base {
    top: $diamond-top + 231px;
    left: $diamond-left + 484px;
  }
  #thrid-base {
    top: $diamond-top + 450px;
    left: $diamond-left + 262px;
  }
  #home-plate {
    position: absolute;
    height: 9px;
    width: 10px;
    top: $diamond-top + 680px;
    left: $diamond-left + 485px;
    border: 1px solid white;
    background-color: white;
  }
  .home-plate-pulse {
    position: absolute;
    height: 7px;
    width: 13px;
    top: $diamond-top + 678px;
    left: $diamond-left + 485px;
  }
  #pitchers-mound {
    position: absolute;
    top: $diamond-top + 420px;
    left: $diamond-left + 450px;
    height: 80px;
    width: 80px;
    border-radius: 100% 100% 100% 100%;
    -webkit-transform: rotate(-45deg);
    border: 1px solid #836539;
    background-color: #836539;
  }
  #pitchers-plate {
    position: absolute;
    top: $diamond-top + 450px;
    left: $diamond-left + 482px;
    height: 16px;
    width: 16px;
    border: 1px solid white;
    background-color: white;
  }
  #third-circle {
    top: $diamond-top + 440px;
    left: $diamond-left + 300px;
    border-radius: 1% 1% 100% 1%;
  }
  #first-circle {
    top: $diamond-top + 440px;
    left: $diamond-left + 642px;
    border-radius: 100% 1% 1% 1%;
  }
  #second-circle {
    top: $diamond-top + 275px;
    left: $diamond-left + 470px;
    border-radius: 1% 1% 1% 100%;
  }
  #batters-box-right {
    top: $diamond-top + 677px;
    left: $diamond-left + 465px;
  }
  #batters-box-left {
    top: $diamond-top + 677px;
    left: $diamond-left + 498px;
  }
  #batting-circle {
    position: absolute;
    top: $diamond-top + 634px;
    left: $diamond-left + 440px;
    height: 100px;
    width: 100px;
    border-radius: 100% 100% 100% 100%;
    -webkit-transform: rotate(-45deg);
    border: 1px solid #836539;
    background-color: #836539;
  }
  #first-base-thing {
    top: $diamond-top + 430px;
    left: $diamond-left + 667px;
    position: absolute;
    height: 170px;
    width: 13px;
    -webkit-transform: rotate(45deg);
    border-right: 2px solid white;
    border-bottom: 2px solid white;
  }
  #vertical {
    position: absolute;
    top: $diamond-top + 14px;
    left: $diamond-left + 491px;
    height: 1115px;
    width: 1px;
    border-left: 1px solid black;
  }
}

@media screen and (max-width: 1300px) {
  $diamond-left: 220px;

  .players-overlay {
    left: 35px;
  }
  #field {
    position: absolute;
    top: $diamond-top - 110px;
    left: $diamond-left + 145px;
    height: 700px;
    width: 700px;
    border-radius: 1% 100% 1% 1%;
    border: 1px solid green;
    background-color: green;
    -webkit-transform: rotate(-45deg);
  }
  #in-field {
    position: absolute;
    top: $diamond-top + 170px;
    left: $diamond-left + 265px;
    height: 450px;
    width: 450px;
    border-radius: 1% 50% 1% 1%;
    border: 1px solid #836539;
    background-color: #836539;
    -webkit-transform: rotate(-45deg);
  }
  #base-lines {
    position: absolute;
    top: $diamond-top - 120px;
    left: $diamond-left + 155px;
    height: 670px;
    width: 670px;
    border-left: 2px solid white;
    border-bottom: 2px solid white;
    -webkit-transform: rotate(-45deg);
  }
  #in-field-grass {
    position: absolute;
    top: $diamond-top + 330px;
    left: $diamond-left + 359px;
    height: 260px;
    width: 260px;
    border: 2px solid green;
    background-color: green;
    -webkit-transform: rotate(-45deg);
  }
  #first-base {
    top: $diamond-top + 450px;
    left: $diamond-left + 706px;
  }
  #second-base {
    top: $diamond-top + 231px;
    left: $diamond-left + 484px;
  }
  #thrid-base {
    top: $diamond-top + 450px;
    left: $diamond-left + 262px;
  }
  #home-plate {
    position: absolute;
    height: 9px;
    width: 10px;
    top: $diamond-top + 680px;
    left: $diamond-left + 485px;
    border: 1px solid white;
    background-color: white;
  }
  .home-plate-pulse {
    position: absolute;
    height: 7px;
    width: 13px;
    top: $diamond-top + 678px;
    left: $diamond-left + 485px;
  }
  #pitchers-mound {
    position: absolute;
    top: $diamond-top + 420px;
    left: $diamond-left + 450px;
    height: 80px;
    width: 80px;
    border-radius: 100% 100% 100% 100%;
    -webkit-transform: rotate(-45deg);
    border: 1px solid #836539;
    background-color: #836539;
  }
  #pitchers-plate {
    position: absolute;
    top: $diamond-top + 450px;
    left: $diamond-left + 482px;
    height: 16px;
    width: 16px;
    border: 1px solid white;
    background-color: white;
  }
  #third-circle {
    top: $diamond-top + 440px;
    left: $diamond-left + 300px;
    border-radius: 1% 1% 100% 1%;
  }
  #first-circle {
    top: $diamond-top + 440px;
    left: $diamond-left + 642px;
    border-radius: 100% 1% 1% 1%;
  }
  #second-circle {
    top: $diamond-top + 275px;
    left: $diamond-left + 470px;
    border-radius: 1% 1% 1% 100%;
  }
  #batters-box-right {
    top: $diamond-top + 677px;
    left: $diamond-left + 465px;
  }
  #batters-box-left {
    top: $diamond-top + 677px;
    left: $diamond-left + 498px;
  }
  #batting-circle {
    position: absolute;
    top: $diamond-top + 634px;
    left: $diamond-left + 440px;
    height: 100px;
    width: 100px;
    border-radius: 100% 100% 100% 100%;
    -webkit-transform: rotate(-45deg);
    border: 1px solid #836539;
    background-color: #836539;
  }
  #first-base-thing {
    top: $diamond-top + 430px;
    left: $diamond-left + 667px;
    position: absolute;
    height: 170px;
    width: 13px;
    -webkit-transform: rotate(45deg);
    border-right: 2px solid white;
    border-bottom: 2px solid white;
  }
  #vertical {
    position: absolute;
    top: $diamond-top + 14px;
    left: $diamond-left + 491px;
    height: 1115px;
    width: 1px;
    border-left: 1px solid black;
  }
}

#player {
  -webkit-transform: rotate(45deg);
}

.undo-trans {
  -webkit-transform: rotate(45deg);
}

.base {
  position: absolute;
  height: 13px;
  width: 13px;
  -webkit-transform: rotate(-45deg);
  border: 1px solid white;
  background-color: white;
}

#home-plate:before,
#home-plate:after {
  top: 100%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}
#home-plate:before {
  border-color: rgba(194, 225, 245, 0);
  border-top-color: white;
  border-width: 6.5px;
  left: 50%;
  margin-left: -6.5px;
}

.half-circle {
  position: absolute;
  height: 40px;
  width: 40px;
  -webkit-transform: rotate(-45deg);
  // border: 1px solid #836539;
  // background-color: #836539;
}

.batters-box {
  position: absolute;
  height: 25px;
  width: 15px;
  border: 2px solid white;
  background-color: #836539;
  outline: 2px solid #836539;
}

.mowed-grass {
  background-color: #ac0;
  background-image: -webkit-gradient(
    linear,
    0 100%,
    100% 0,
    color-stop(0.25, rgba(255, 255, 255, 0.2)),
    color-stop(0.25, transparent),
    color-stop(0.5, transparent),
    color-stop(0.5, rgba(255, 255, 255, 0.2)),
    color-stop(0.75, rgba(255, 255, 255, 0.2)),
    color-stop(0.75, transparent),
    to(transparent)
  );
  background-image: -webkit-linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.2) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.2) 50%,
    rgba(255, 255, 255, 0.2) 75%,
    transparent 75%,
    transparent
  );
  background-image: -moz-linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.2) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.2) 50%,
    rgba(255, 255, 255, 0.2) 75%,
    transparent 75%,
    transparent
  );
  background-image: -ms-linear-gradient(
    0deg,
    rgba(255, 255, 255, 0.2) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.2) 50%,
    rgba(255, 255, 255, 0.2) 75%,
    transparent 75%,
    transparent
  );
  background-image: -o-linear-gradient(
    0deg,
    rgba(255, 255, 255, 0.2) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.2) 50%,
    rgba(255, 255, 255, 0.2) 75%,
    transparent 75%,
    transparent
  );
  background-image: linear-gradient(
    0deg,
    rgba(255, 255, 255, 0.2) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.2) 50%,
    rgba(255, 255, 255, 0.2) 75%,
    transparent 75%,
    transparent
  );
  -webkit-background-size: 250px 250px;
  -moz-background-size: 50px 50px;
  background-size: 200px 200px;

  background-color: #ac0;
}

.action-area {
  position: relative;
  z-index: 2000;
  cursor: pointer;
  width: 100px;
  height: 100px;
  margin: -50px;
}

.pulse {
  // z-index: 1000;
  // /*background: red;*/
  // border: 1px solid #999;
  // /*border-color: blue;*/
  // border-radius: 100%;
  // margin: -8px; /* -0.5 * baseWidth - baseBorder */
  // width: 27px; /*  2 * baseWidth + baseBorder */
  // height: 27px;
  // -webkit-animation: pulsate 1s ease-out;
  // -webkit-animation-iteration-count: infinite;
  // animation: pulsate 1s ease-out;
  // animation-iteration-count: infinite;
  // opacity: 0;
}

@-webkit-keyframes pulsate {
  0% {
    -webkit-transform: scale(1, 1);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(2, 2);
    opacity: 0;
  }
}
@keyframes pulsate {
  0% {
    -webkit-transform: scale(1, 1);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(2, 2);
    opacity: 0;
  }
}
