.outer-pl-container {
  margin-left: 1rem;
  padding: 1rem;
  background-color: #fff;
  display: flex;
  min-height: 90vh;
  display: flex;
  flex-direction: row;
}
.player-container {
  flex-direction: column;
  display: flex;
  flex: 1;

  border-radius: 10px;
}
.top-row {
  display: flex;
  flex-direction: row;

  align-items: center;
}
.middle-row {
  display: flex;
  flex-direction: row;
  padding: 10px 0px;
}

/* Picture */

.profile-picture {
  width: 250px;
  height: 350px;
  border-radius: 9px;
  /* background-color: #003366; */
  object-fit: contain;
}

.monitor-profile-pic {
  width: 80px;
  height: 80px;
  border-radius: 40px;
  /* background-color: #003366; */
  object-fit: contain;
}

/* Middle Text */
.middle-text-container {
  padding: 0px 10px;
}
.middle-text-small {
  display: flex;
  flex-direction: row;
  font-family: "Raleway";
  font-size: 16px;
}
.middle-text-bold {
  font-family: "Raleway";
  font-weight: bold;
  margin-left: 5px;
  font-size: 16px;
}

/* Stats */

.stats-top-row {
  border-bottom: 2px solid white;
  padding: 0px 10px;
}
.stats-title {
  font-size: 30px;
}
.stats-middle {
  display: flex;
  flex-direction: row;
}
.stats-middle-left {
  display: flex;
  flex: 1;

  flex-direction: column;
}
.stats-middle-right {
  display: flex;

  flex: 1;
  flex-direction: column;
}
.stats-text-small {
  padding: 0px 10px;
  display: flex;
  flex-direction: row;
}
.stats-text-bold {
  font-weight: bold;
  margin-left: 5px;
}

/* Reports */
.reports-container {
  margin-top: 10px;
}
.reports-top-row {
  border-bottom: 2px solid white;
  padding: 0px 15px;
}
.reports-title {
  font-size: 30px;
}

/* Upcoming */
.upcoming-container {
  margin-top: 10px;
}
.upcoming-top-row {
  border-bottom: 2px solid white;
  padding: 0px 15px;
}
.upcoming-title {
  font-size: 30px;
}

/* Analysis */
.pitch-analysis-row:nth-child(even) {
    background-color: #f2f2f2;
}

.visibleScrollbar::-webkit-scrollbar {
    display: block;
}
