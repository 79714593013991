@keyframes move-left {
  from {
    transform: translateX(10px);
  }
  to {
    transform: translateX(0px);
  }
}

@keyframes move-left-in {
  from {
    transform: translateX(10px);
    opacity: 0;
  }
  to {
    transform: translateX(0px);
    opacity: 1;
  }
}

@keyframes move-right-out {
  from {
    transform: translateX(0px);
    opacity: 1;
  }
  to {
    transform: translateX(-10px);
    opacity: 0;
  }
}

.main-input {
  min-width: 280px;
  border-width: 0px;
  box-shadow: none;
  max-width: 280px;
}

.main-input:focus {
  min-width: 280px;
  border-width: 0px !important;
  box-shadow: none !important;
  max-width: 280px;
}

.chip-input {
  box-shadow: none;
  border: none;
  transition: border 0.15s linear, box-shadow 0.15s linear;
  max-width: 280px;
  flex-wrap: wrap;
  padding: 4px 10px;
}

.chip-input .chip {
  margin-right: 2px;
  margin-bottom: 2px;
  transform: translateX(10px);
  opacity: 0;
  padding: 2px 4px 3px 8px;
  border: 1px solid rgba(256, 256, 256, 0.25);
  border-radius: 2px;
  font-size: 14px;
  /* height: 32px; */
  background-color: #467db3;
  transition: box-shadow 0.15s linear, background-color 0.15s linear,
    border 0.15s linear;
  color: #fff;
}

.chip-input .chip:hover,
.chip-input .chip:focus,
.chip-input .chip:active {
  border-color: var(
    --react-chip-input__chip-hover-border-color,
    var(--dark, rgba(256, 256, 256, 0.5))
  );
  background-color: #467db3;
}

.chip-input .chip.show {
  animation: move-left-in 0.25s ease-in-out forwards;
}

.chip-input .chip.hide {
  animation: move-right-out 0.25s ease-in-out forwards;
}

.chip-input .chip svg {
  transition: height 0.15s linear, width 0.15s linear;
  transform: scale(0.5);
  margin-left: -6px;
}

.chip-input .chip svg:hover,
.chip-input .chip svg:focus,
.chip-input .chip svg:active {
  transform: scale(1);
}

.no-focus:focus {
  box-shadow: none !important;
}

.shadow-primary {
  box-shadow: 0 0 0 0.2rem
    var(--react-chip-input__box-shadow-color, var(--primary, blue));
}

.cursor-pointer {
  cursor: pointer;
}
