.report-row {
  display: flex;
  flex-direction: row;
  flex: 1;
  margin-bottom: 20px;
}

.report-row p {
  font-weight: 700;
}

.custom-file-upload {
  border: 2px solid #003366;
  width: 80px;
  height: 80px;
  border-radius: 8px;
  margin-top: 12px;
  margin-right: 20px;
  cursor: pointer;
}

input[type="file"] {
  display: none;
}

.searching ::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #fff;
  opacity: 0.5; /* Firefox */
}
.report-row ::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #000;
  opacity: 0.5; /* Firefox */
}
