.set-outer-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.settings-container {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  padding-left: 4rem;
  padding-right: 4rem;
  margin-left: 15%;
  margin-right: 15%;
  border-radius: 15px;
  border-radius: 15px;
}
.title-text {
  font-family: "Raleway";
  font-weight: 300;
  margin-bottom: 1rem;
}

.inner-container {
  background-color: #fff;
  width: 36vw;

  border-radius: 15px;
}
.row-container {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid white;
  justify-content: space-between;
  align-items: center;

  padding: 0.5rem 1rem;
}

.row-text {
  margin-bottom: 0px;
  color: #003366;
  /* font-size: 25px; */
  font-weight: 600;
  font-family: "Raleway";
  /* border: 1px solid black; */
}

.chevron {
  color: #003366;
  font-size: 36px;
}
